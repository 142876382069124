@keyframes map-fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}

.map-fade-in {
  opacity: 0;
  animation: map-fade-in 2.5s 1.5s forwards;
}

.static-map {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: calc(100% - 65px);
    width: calc(100% - 70px);
    opacity: 0.2;
    object-fit: cover;
    display: inline-block;
    filter: grayscale(100%);
  }
}