body {
  overflow: hidden;

  .content {
    width: 75%;
    max-width: 1200px;
    margin: 0 auto;
  }
}


