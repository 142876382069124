@keyframes summary-fade-in {
  from { opacity: 0; transform: scale(0); }
  to { opacity: 1; transform: scale(1); }
}

.summary-fade-in {
  opacity: 0;
  transform: scale(0);
  animation: summary-fade-in 1.45s 2.5s forwards;
}

.city-summary {
  background-color: white;
  padding: 25px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05), 0 6px 6px rgba(0, 0, 0, 0.05);
  margin-top: 30px;
  font-family: 'PT Serif', serif;
  font-size: 1.25em;
  z-index: -1;

  p {
    margin-bottom: 0;

    a {
      margin-left: 10px;
    }
  }
}