@keyframes day-fade-in {
  from {top: 50px; opacity: 0;}
  to {top: 0px; opacity: 1;}
}

.forecast {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;

  .day {
    background-color: white;
    min-width: 200px;
    max-width: 210px;
    padding: 20px 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05), 0 6px 6px rgba(0, 0, 0, 0.05);

    .condition-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: rgb(156, 160, 161);
      padding-bottom: 7.5px;
      border-bottom: 1px solid lightgrey;

      img {
        width: 64px;
        margin-bottom: 10px;
      }      
    }

    .temp {
      text-align: center;
      margin-top: 10px;

      .degrees {
        text-align: center;
        color: rgb(68, 68, 68);
        margin-bottom: 10px;

        p {
          font-size: 2.5em;
          line-height: 1em;
        }
      }

      .unit-change {
        font-size: 1.1em;

        .change-fn {
          color: #4949ef;
          cursor: pointer;
        }

        .disabled {
          cursor: default;
        }

        .separator {
          padding: 0 5px;
        }
      }
    }

    .day-of-week {
      padding-top: 15px;

      p {
        text-align: center;
      }
    }
  }

}