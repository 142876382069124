@keyframes border-size {
  from { width: 0; }
  to { width: 100%; }
}

@keyframes name-drop {
  from { top: -70px; }
  to { top: 0; }
}

.border-size {
  width: 0 !important;
  animation: border-size 1s 1s forwards;
}

.name-drop {
  top: -70px;
  animation: name-drop 0.8s 0.5s forwards;
}

.city-name {
  display: absolute;
  top: 0;
  width: calc(100% - 70px);
  margin: 0 auto;
  text-align: center;

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    h1 {
      position: relative;
      display: flex;
      align-items: center;
      height: 70px;
      margin: 0;
      padding: 5px 15px;
      background-color: rgb(24, 24, 24);
      color: white;
      font-size: 2.8em;
      text-align: center;      
      font-family: 'PT Serif', serif;
      font-weight: 400;
    }
  
    .border {
      height: 7px;
      background-color: rgb(24, 24, 24);
      position: absolute;
      z-index: -1;
      width: 100%;
    }
  }
}